export const saleFiles = [
  { label: '日期范围', prop: 'dateRange', placeholder: '' },
  { label: '订单类型', prop: 'orderType1', placeholder: '请输入订单类型' }
]
export const userFiles = [{ label: '分销商', prop: 'mainUser', placeholder: '输入分销商名称' }]
export const styleFiles = [
  { label: '原型分类', prop: 'categoryName', placeholder: '选择分类' },
  { label: '款式', prop: 'styleName', placeholder: '请输入款式/款式编码' },
  { label: '日期范围', prop: 'dateRange', placeholder: '' },
  { label: '订单类型', prop: 'orderType', placeholder: '请输入订单类型' }
]
export const SKUFiles = [
  { label: '原型分类', prop: 'categoryName', placeholder: '选择分类' },
  { label: '分销商', prop: 'mainUser', placeholder: '请输入分销商名称' },
  { label: '日期范围', prop: 'dateRange', placeholder: '' },
  { label: '订单类型', prop: 'orderType', placeholder: '请输入订单类型' }
]
export const orderFiles = [
  { label: '日期范围', prop: 'dateRange' },
  { label: '订单类型', prop: 'orderType1', placeholder: '请输入订单类型' }
]
